<div [class]="'theme-wrapper ' + 'default-theme'">
  <mat-sidenav-container>
    <mat-sidenav #sidenav
                 mode="push">
      <div class="branding">
        <img alt="logo"
             [src]="logo" />
        <span>{{ 'tcd.title.short' | translate}}</span>
      </div>
      <mat-nav-list>
        <a mat-list-item
           *ngFor="let item of navigationSideMenu"
           (click)="sidenav.close()"
           [routerLink]="[item.link]"
           routerLinkActive="list-item-active">
          {{item.label | translate}}
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <div class="wrapper">

      <div class="toolbar">
        <nav class="navbar navbar-expand-md">
          <button mat-icon-button
                  class="d-md-none"
                  (click)="sidenav.open()">
            <fa-icon icon="bars"></fa-icon>
          </button>

          <span routerLink=""
                class="branding center d-inline d-sm-none">
            <img alt="logo"
                 [src]="logo" />
          </span>

          <span routerLink=""
                class="branding center d-none d-sm-inline d-md-none">
            <img alt="logo"
                 [src]="logo" />
            {{'tcd.title.short' | translate }}
          </span>

          <span routerLink=""
                class="branding d-none d-md-inline">
            <img alt="logo"
                 [src]="logo" />
            {{ 'tcd.title.long' | translate}}
          </span>

          <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-3 mr-auto">
              <li class="nav-item"
                  *ngFor="let e of examples">
                <a [routerLink]="e.link"
                   [ngClass]="{'active navigate-active': rla.isActive}"
                   routerLinkActive="is-Active"
                   #rla="routerLinkActive">
                  {{e.label | translate}}
                </a>
              </li>
            </ul>
          </div>

          <span class="spacer"></span>

          <button type="button"
                  mat-icon-button
                  aria-label="logOut"
                  class="d-sm-inline mr-3"
                  (click)="onLogoutClick()">
            <fa-icon class="icon-action fa-lg"
                     [icon]="faLogout">
            </fa-icon>
          </button>
        </nav>
      </div>

      <div class="content"
           [@routeAnimations]="o.isActivated">
        <router-outlet #o="outlet"></router-outlet>
      </div>

      <!-- <div class="footer">
        <div class="row">
          <div class="col-sm-12 links">

          </div>
        </div>
        <div class="row">
          <div class="col-12 signature"
               style="color: black;">
            &#169; <span class="year">{{year}}</span> - TUI
            <br class="d-block d-sm-none">
          </div>
        </div>
      </div> -->
    </div>
  </mat-sidenav-container>

</div>
