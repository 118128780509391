import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faMediumM, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { TooltipModule, ButtonsModule, WavesModule, ModalModule } from 'angular-bootstrap-md';
import { TagInputModule } from 'ngx-chips';

import { NgSelectModule } from '@ng-select/ng-select';

library.add(faBars, faGithub, faMediumM, faTwitter, faInstagram, faYoutube);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    TranslateModule,

    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    MatSnackBarModule,

    TagInputModule,

    FontAwesomeModule,

    NgSelectModule,

    TooltipModule,
    ButtonsModule,
    WavesModule,
    ModalModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,

    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    MatSnackBarModule,

    TagInputModule,

    FontAwesomeModule,

    NgSelectModule,

    TooltipModule,
    ButtonsModule,
    WavesModule,
    ModalModule
  ]
})
export class SharedModule {}
