import { KeycloakConfig } from 'keycloak-angular';
import { EnvironmentInterface } from './environment-interface';

const keycloakConfig: KeycloakConfig = {
  url: 'https://passport.test.tui-destiservices.com/auth',
  realm: 'one-purchasing',
  clientId: 'tcd-dev'
};

export const environment: EnvironmentInterface = {
  appName: 'Terms Conditions Dispatcher',
  envName: 'DEV',
  production: false,
  test: false,
  i18nPrefix: '',
  keycloak: keycloakConfig,
  // API_URL: 'http://127.0.0.1:8080/'
  API_URL: 'https://tcd-ui-backend.dev.tui-destiservices.com/'
};
