import browser from 'browser-detect';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as env } from '../../environments/environment';

import { routeAnimations } from '../core/core.module';
import { TranslateService } from '@ngx-translate/core';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'tcd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  isProd = env.production;
  envName = env.envName;
  year = new Date().getFullYear();
  logo = require('../../assets/logo.png');
  faLogout = faSignOutAlt;
  languages = ['en'];
  navigation = [
    { link: 'about', label: 'tcd.menu.about' },
    { link: '/rules/list', label: 'Overview' },
    { link: 'rules/new-rule', label: 'tcd.features.rule' }
  ];
  navigationSideMenu = [...this.navigation];

  examples = [
    { link: 'rules/list', label: 'Overview' },
    { link: 'rules/new-rule', label: 'Create rule' }
  ];

  isAuthenticated$: Observable<boolean>;
  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: string;

  constructor(private translateService: TranslateService, private keycloak: KeycloakService) {}

  ngOnInit(): void {
    this.theme$ = 'theme';
    this.translateService.use('en');
  }

  onLogoutClick() {
    this.keycloak.logout();
  }
}
