import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AppKeycloakGuard } from './guard/app.keycloakguard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'rules',
    pathMatch: 'full'
  },
  {
    path: 'about',
    canActivate: [AppKeycloakGuard],
    loadChildren: () => import('./features/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'rules',
    canActivate: [AppKeycloakGuard],
    loadChildren: () => import('./features/feature-rules/rules.module').then(m => m.RulesModule)
  },
  {
    path: '**',
    redirectTo: 'rules'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [AppKeycloakGuard]
})
export class AppRoutingModule {}
