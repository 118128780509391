import { Injectable } from '@angular/core';

const APP_PREFIX = 'TCD_v2';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  constructor() {}

  loadInitialState() {
    if (localStorage.length < 1) {
      return undefined;
    }

    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const currentStateRef = state;
        // stateKeys.forEach((key, index) => {
        //   if (index === stateKeys.length - 1) {
        //     currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
        //     return;
        //   }
        //   currentStateRef[key] = currentStateRef[key] || {};
        //   currentStateRef = currentStateRef[key];
        // });
      }
      return state;
    }, {});
  }

  setItem(value: any) {
    localStorage.setItem(`${APP_PREFIX}`, JSON.stringify(value));
  }

  getItem() {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}`));
  }

  removeItem() {
    localStorage.removeItem(`${APP_PREFIX}`);
  }
}
